.c-rss-cta {
  background-color: color('neutral', 'step--3');
  overflow: hidden;

  &__social {
    display: flex;
    gap: var(--space-xs);

    // a {
    //   transition: 300ms background;

    //   &:hover {
    //     opacity: 0.8;
    //   }
    // }
  }

  &__primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: min(45%, 200px);
    padding-top: var(--space-xl);

    > div {
      max-width: 460px;
    }

    form {
      max-width: 500px;

      label {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
  
      .c-input-inline-button {
        display: flex;
        gap: var(--space-s);
        flex-wrap: wrap;
  
        > * {
          flex-grow: 1;
        }
      }
  
      .c-input {
        background: $blue-gradient;
        margin-top: 0;
      }
  
      .c-button {
        // background: $primary-gradient;
        // border-color: transparent;
        font-size: var(--step-4);
        padding: var(--space-xs);
        max-width: 160px;
        width: auto;
        margin-top: 0;
		display: inline-flex;
        // top: 0;
  
        // &:hover,
        // &:active,
        // &:focus {
        //   border: 2px solid transparent;
        // }
      }
    }
  }

  &__side {
    // background: color('primary');
	background-color: $blue-bg;
    position: relative;
    margin-left: calc(var(--negative-gutter));
    padding: min(80%, 270px) 0 var(--gutter) var(--gutter);
    
    .o-grid & {
      border-left: none;
    }

    .u-step-3 {
      line-height: 1.333;
    }

    .u-step-4 {
      line-height: 1.16;
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: var(--bleed-space);
    //   background-color: color('primary');
	  background-color: color('neutral', 'step--3');
      left: 100%;
      top: 0;
    }
  }

  &__graphic {
    position: absolute;
    right: var(--negative-gutter);
    top: 0;
    transform: translateY(-50%);
    pointer-events: none;
    width: calc(100% + var(--gutter));
    z-index: 1;
    max-width: 400px;
    
    > * {
      padding-bottom: 125%;

      > * {
        object-fit: contain;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  @media (min-width: 50em) {
    &__graphic {
      right: 100%;
      transform: translateY(-5%) translateX(35%);
      width: 175%;
      z-index: 0;
      max-width: 650px;

      > * {
        padding-bottom: 84%;
      }
    }

    &__primary {
      padding-block: var(--gutter);
    }

    &__side {
      margin-left: 0;
      padding-top: var(--space-3xl);
      padding-bottom: var(--space-2xl);
    }
  }

  @media (min-width: 75em) {
    &__graphic {
      transform: translateY(-5%) translateX(45%);
      width: 200%;
      max-width: 750px;
    }

    &__side {
      padding-top: calc(var(--space-3xl) + var(--space-l));
    }
  }

  &__rss {
    // text-decoration: underline;
    // text-decoration-thickness: 1px;
    font-family: $font-stack-heading;
    font-weight: 700;

    svg {
      height: 1.875rem;
      width: 1.875rem;
    }
  }
}
