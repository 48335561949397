.c-newsletter:target .c-newsletter__content {
  outline: 2px dashed color('primary');
  outline-offset: 20px;
}

.c-newsletter {
  text-align: center;
  margin-top: var(--space-xl-2xl);

  &__content {
    background: rgba(0, 0, 0, 0.25);
    padding: var(--space-s) var(--space-s-l);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter);
  }

  &__image {
    text-align: center;
    width: 70%;
    margin: calc(-1 * var(--space-2xl-3xl)) auto 0;

    svg {
      width: 65%;
      height: auto;
      overflow: hidden;

      @media (min-width: 70em) {
        width: 100%;
      }
    }
  }

  .c-input {
    text-align: inherit;
  }

  @media (min-width: 50em) {
    text-align: left;

    &__content {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    &__image {
      grid-column: span 2;
      width: 40%;
    }
  }

  @media (min-width: 70em) {
    margin-top: 0;

    &__content {
      grid-template-columns: 1fr 2fr 3fr;
    }

    &__image {
      grid-column: span 1;
      width: 180px;
      margin: calc(-0.5 * var(--space-l-xl)) 0 0;
    }
  }
}
