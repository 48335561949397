.c-image-bleed {
  &__image {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(229.29deg, #4c535a 0%, #eef4fa 100%);
      z-index: 1;
      mix-blend-mode: multiply;
      opacity: 10%;
    }

    .c-cta-primary {
      margin: var(--negative-gutter) var(--gutter) 0;
    }
  }

  &__trigger {
    display: inline-flex;
  }

  &__image &__trigger {
    transform: translateY(-50%);
    position: absolute;
    z-index: 5;
    right: var(--gutter);
    top: 100%;
  }

  @media (max-width: 799px) {
    &__image {
      margin-right: var(--negative-gutter);
      margin-left: var(--negative-gutter);
      margin-top: var(--space-m-l);
    }
  }

  @media (min-width: 800px) {
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: var(--gutter);
      align-items: center;
    }

    &__image {
      margin-right: var(--negative-gutter);
      grid-column: 6 / 13;
      grid-row: 1 / 1;
      min-height: 580px;
      margin-top: 0;

      img {
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }

      .c-cta-primary {
        position: absolute;
        top: 100%;
        margin-left: calc(var(--gutter) * 2);
      }
    }

    &__content {
      grid-column: 1 / 7;
      grid-row: 1 / 1;
      order: 1;
      z-index: 1;
    }

    &--reversed &__image {
      grid-column: 1 / 7;
      margin-left: var(--negative-gutter);

      img {
        --border-radius: 0;
      }
    }

    &--reversed &__content {
      grid-column: 6 / 13;
      padding-left: var(--gutter);

      > svg {
        float: right;
        margin-bottom: 1em;

        & + *:before {
          content: '';
          display: table;
          clear: both;
        }
      }
    }
  }

  @media (min-width: 1272px) {
    &__image {
      margin-right: calc(-1 * ((100vw - var(--wrapper)) / 2));
      grid-column: 5 / 13;
    }

    &__content {
      grid-column: 1 / 6;
    }

    &--reversed &__image {
      margin-left: calc(
        -1 * (var(--gutter) + (100vw - var(--wrapper-with-gutters)) / 2)
      );
      margin-right: 0;
    }
  }

  @media (min-width: 1600px) {
    &__image {
      margin-right: -200px;
    }
    
  }

  #svg-tea {
    overflow: visible;
  }
}
