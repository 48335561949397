.c-accordion {
  & > li {
    border-bottom: 1px solid rgba(255 255 255 / 10%);
  }

  button {
    &:active,
    &:focus {
      outline-offset: 0;
    }
  }

  &__trigger {
    font-family: $font-stack-heading;
    font-weight: 700;
    padding: var(--space-xs) var(--space-m) var(--space-xs) var(--space-xs);
    position: relative;
    width: 100%;

    &:before,
    &:after {
      content: '';
      width: 14px;
      height: 3px;
      background: color('primary', 'step-1');
      position: absolute;
      right: 10px;
      top: 42%;
      transition: 300ms transform;
    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  &__content {
    border-radius: var(--border-radius);
    padding: var(--space-xs);
    margin-bottom: var(--space-m);

    &:focus,
    &:active {
      outline-offset: 5px;
    }
  }

  .has-js & > li:not(.c-accordion--open) &__content {
    display: none;
  }

  .no-js &__trigger:after,
  &--open &__trigger:after {
    transform: rotate(0deg);
  }
}
