.c-lessons {
  padding: var(--space-2xl-3xl) 0;
  overflow: hidden;

  &__wrapper {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
    counter-reset: lesson;
  }

  &__slides {
    width: calc(100vw - var(--gutter));

    @media (min-width: 80em) {
      max-width: 1180px;
    }
  }

  &__controls {
    position: absolute;
    width: 100%;
    color: color('dynamic', 'on-primary');
    display: flex;
    justify-content: space-around;
    margin-top: var(--space-s);

    @media (min-width: 80em) {
      right: -25px;
    }

    button {
      display: flex;

      &:focus,
      &:active {
        border-radius: 50%;
      }
    }

    .no-js & {
      display: none;
    }

    .fade-out {
      opacity: 0.4;
    }
  }

  &__count {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: var(--space-s);
    color: color('white');
    font-family: $font-stack-heading;
    font-weight: 700;
    font-size: var(--step--1);
    padding: var(--space-2xs);
    z-index: 2;
  }

  &__slides {
    margin-right: var(--negative-gutter);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -20px;
      bottom: -1px;
      height: 3px;
      width: calc(100% + var(--gutter));
      background: color('white');
      border-radius: 6px;
    }
  }

  @media (min-width: 45em) {
    &__wrapper {
      grid-template-columns: 5fr 7fr;
      align-items: center;
    }

    &__slides {
      width: calc(100vw - var(--gutter) - 41.666vw);
    }

    &__controls {
      right: 0;
      top: 100%;
      left: auto;
      width: auto;
      font-size: 1.5em;
      padding-right: var(--gutter);

      & > * + * {
        margin-left: var(--space-xs);
      }
    }

    &__count {
      left: -40px;
      bottom: 5px;
      top: auto;
      transform: translateX(-100%);
      padding-bottom: var(--space-2xs);
      font-weight: 400;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        height: 3px;
        width: 100%;
        background: color('white');
        border-radius: 6px;
      }
    }
  }

  @media (min-width: 50em) {
    &__wrapper {
      grid-template-columns: 4fr 8fr;
    }

    &__slides {
      width: calc(100vw - var(--gutter) - 33.333vw);
    }
  }

  @media (min-width: 60em) {
    &__wrapper {
      grid-template-columns: 4fr 8fr;
    }

    &__slides {
      width: calc(100vw - var(--gutter) - 33.333vw);
    }
  }

  @media (min-width: 1272px) {
    &__slides {
      margin-right: calc(-1 * ((100vw - 1100px) / 2));
    }

    &__controls {
      right: 50px;
    }
  }

  &__slider {
    ol {
      display: flex;
      align-items: stretch;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      padding-right: 20px;

      li {
        scroll-snap-align: start;
        width: 263px;

        &:last-child {
          scroll-snap-align: end;
        }
      }

      & > * + * {
        margin-left: var(--space-l);
      }
    }
  }
}

.c-lesson {
  border: 2px solid color('white');
  border-top-right-radius: var(--space-xs);
  width: 263px;
  padding-left: 20px;
  position: relative;
  counter-increment: lesson;
  background: color('neutral');
  font-size: var(--step-0);
  padding-bottom: 80px;
  min-height: 100%;
  margin-top: 25px;
  overflow: hidden;

  --slide-height: 325px;

  &:after {
    content: '';
    background: linear-gradient(
      rgba(color('neutral'), 0),
      rgba(color('neutral'), 1)
    );
    position: absolute;
    height: 75px;
    bottom: 0;
    right: 0;
    left: 21px;
    z-index: 1;
  }

  & {
    --slide-offset: 0px;
    transform: translateY(
      calc(#{var(--slide-height)} - 260px - var(--slide-offset))
    );
    transition: 250ms transform ease-in-out;
    cursor: pointer;

    &:hover {
      --slide-offset: 80px;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    & {
      transition: 100ms transform ease-in-out;
    }

    &:hover {
      --slide-offset: 5px;
    }
  }

  &:before {
    content: '';
    height: 100%;
    border-left: 1px solid color('white');
    position: absolute;
  }

  &__header {
    padding: 20px;
    font-family: $font-stack-heading;
    font-weight: 700;
    font-size: var(--step--1);
  }

  &__content {
    padding: 20px;
    position: absolute;
  }

  &__number {
    color: color('primary');

    &:after {
      content: counters(lesson, '', decimal-leading-zero);
    }
  }

  &__title {
    min-height: 48px;

    &-link {
      text-decoration: underline;
      font-size: var(--step-2);
      color: color('white', 'step-1');
      text-decoration-skip-ink: auto;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
      text-decoration-color: color('primary');

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
  }

  &--shelved {
    position: relative;
  }
}

.c-lessons__rack {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;

  & > * {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, 1fr);
    align-items: end;
    grid-gap: var(--gutter);
  }

  .c-lesson {
    --slide-offset: 0px;
    height: auto;
    width: 100%;
    max-width: 320px;

    transform: translateY(calc(100% - 260px - var(--slide-offset)));

    &:hover {
      --slide-offset: 20px;
    }

    &--active {
      transform: translateY(0);
    }

    &__trigger {
      &:focus:not(:focus-visible) {
        outline: none;
      }
    }
  }

  & > * {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 1272px;
      left: 0;
      bottom: 0;
      border-top: 2px solid color('white');
    }
  }

  @media (min-width: 610px) {
    & > * {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 920px) {
    & > * {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1240px) {
    & > * {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.c-view-tab {
  &__controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-2xs);

    a {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: color('neutral', 'step--1');
      color: color('primary');

      &[aria-expanded] {
        background: color('neutral', 'step-1');
        color: color('white');
      }
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 799px) {
    &__controls,
    &--short {
      display: none;
    }
  }

  @media (min-width: 800px) {
    display: none;

    &--active {
      display: block;
    }
  }
}
