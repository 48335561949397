.c-full-lesson {
  counter-increment: lesson;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-xs);
  background: color('neutral', 'step--1');
  padding: var(--space-s);
  scroll-margin-top: 140px;

  & + & {
    border-top: 1px solid color('neutral');
  }

  &__number {
    text-align: center;

    &:after {
      content: counters(lesson, '', decimal-leading-zero);
    }
  }

  &__length {
    font-family: $font-stack-heading;
    font-size: var(--step--1);
    display: flex;
    align-items: center;

    .c-icon {
      font-size: 2.1875rem;
      margin-right: var(--space-s);
    }
  }

  @media (min-width: 30em) {
    grid-template-columns: 40px 1fr;
    align-items: center;
    grid-gap: var(--space-s-l);

    .o-body,
    img,
    &__length {
      grid-column: 2 / 3;
    }
  }

  @media (min-width: 50em) {
    grid-template-columns: 40px 0.75fr 1fr 80px;
    padding: var(--space-s-l);

    .o-body,
    img,
    &__length {
      grid-column: auto;
    }

    &__length {
      text-align: center;
      flex-direction: column;
      justify-content: center;
      gap: var(--space-xs);
    
      .c-icon,
      time {
        display: block;
        margin: 0;
      }
    }
  }

  @media (min-width: 60em) {
    grid-template-columns: 40px 0.75fr 1fr minmax(120px, 180px);

    &__length {
      height: 120px;
      border-left: 1px solid color('neutral');
    }
  }

  @media (min-width: 60em) {
    grid-template-columns: 80px 0.8fr 1fr 200px;
  }
}
