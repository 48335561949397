.c-cta {
  margin-top: var(--space-xl);

  &__content {
    background: rgba(0, 0, 0, 0.25);
    padding: var(--space-s-m);
    border-radius: 6px;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin: calc(4 * var(--negative-gutter)) 0 var(--gutter);
    
    img {
      width: 100%;
      height: 100%;
      max-width: 243px;
      object-fit: contain;
      object-position: 50% 100%;
    }
  }

  .c-icon--spark {
    position: absolute;
  }

  &-spark-1 {
    font-size: 2em;
    left: 14%;
    top: 0;
  }

  &-spark-2 {
    left: 6%;
    top: 10%;
  }

  &-spark-3 {
    left: 10%;
    top: 35%;
  }

  @media (min-width: 40em) {
    margin-top: 0;

    &__content {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 5fr 7fr;
    }

    &__image {
      position: relative;
      margin-bottom: calc(-1.75 * var(--gutter));
      justify-content: flex-end;
    }
  }

  @media (min-width: 50em) {
    &__content {
      grid-template-columns: 4fr 8fr;
      padding-right: var(--space-l-xl);
    }


  }
}
