.c-modal {
  content-visibility: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 var(--gutter);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
  display: flex;

  &--open,
  &:target {
    opacity: 1;
    visibility: visible;
  }

  &__wing {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    width: var(--gutter);
    z-index: 2;

    & + & {
      right: 0;
      left: auto;
    }

    @media (min-width: 1272px) {
      width: calc(50vw - 1272px / 2 + var(--gutter));
    }
  }

  &:after {
    content: '';
    position: absolute;
    background: color('neutral');
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__wrapper {
    width: calc(100% + var(--gutter) * 2);
    z-index: 1;
    overflow-y: auto;
    padding-bottom: var(--space-s);
    -webkit-overflow-scrolling: touch;
  }
  
  &__close {
    background: color('neutral', 'step--1');
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    right: var(--gutter);
    top: 50%;
    border-radius: 100%;
    transition: 300ms transform, 300ms background;
    transform: translateY(-50%);

    &:hover {
      background: color('neutral', 'step--2');
      transform: translateY(-50%) rotate(90deg);
    }

    &:before,
    &:after {
      content: '';
      width: 22px;
      height: 1px;
      background: color('primary');
      position: absolute;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      left: 50%;
      top: 50%;
    }

    &:after {
      height: 22px;
      width: 1px;
    }
  }
}
