.c-article-tags {
  --button-width: 85px;
  --link-height: 38px;

  background: color('neutral', 'step--4');

  ul {
    display: flex;
    gap: var(--space-s) var(--space-xs);
    flex-wrap: wrap;
    padding: var(--space-s) 0;
	
	position: relative;
	padding-left: 15px;
	left: -15px;

    &.c-article-tags--loading {
      max-width: calc(100% - var(--button-width) - var(--space-xs));
    }
    
    &:not(.c-article-tags--open) {
      height: calc(35px + var(--space-s) * 2);
      overflow: hidden;
    }

    > * {
      flex-shrink: 0;
    }
  }
  
  &__final {
    margin-right: var(--button-width);
  }

  a {
    background: $blue-gradient;
    padding: 0.5em var(--space-2xs-xs) 0.5em;
    color: color('white');
    border-radius: var(--space-2xs);
    font-weight: 700;
    font-size: var(--step-0);
    font-family: $font-stack-heading;
    transition: 300ms color;
    height: var(--link-height);
    display: block;
	  text-shadow: 1px 1px 1px rgba(0,0,0,30%);

	  position: relative;
    top: -2px;
    transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &[aria-current="page"],
    &:hover {
		color: color('white');
		background: $button-hover-gradient;
    }
	
    &:active,
    &:focus {
      top: 3px;
      box-shadow: 0 4px 0 transparent;
      background: $primary-gradient;
    }
  }

  button {
    background: $primary-gradient;
    padding: 0.3em var(--space-2xs) 0.3em;
    border-radius: var(--space-3xs);
    font-weight: 700;
    font-size: var(--step-0);
    font-family: $font-stack-heading;
    align-self: flex-start;
    height: var(--link-height);
    width: var(--button-width);
    text-align: center;
    display: block;
    overflow: hidden;
    flex-shrink: 0;
	  margin: var(--space-s) 0;
    position: absolute;
    top: -2px;
    opacity: 0;

    &[style] {
      opacity: 1;
    }

    &.c-article-tags-trigger--hidden {
      display: none;
    }
  }
}