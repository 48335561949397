.c-cta {

  &-spark-4 {
	font-size: 1.3em;
    right: 19%;
    top: 19%;
  }

  &-spark-5 {
    font-size: 0.6em;
    left: 17%;
    top: 24%;
  }

  &-spark-6 {
	left: 18.2%;
    top: 15%;
  }
  
    &__license {
	    margin: 0 0 var(--space-m) 0;
    }

	@media (min-width: 40em) {
    &__license {
      margin-bottom: 0;
      justify-content: center;
    }

	&-spark-4 {
		left: 25.6%;
		top: 32%;
	}

	&-spark-5 {
		left: 10%;
		top: 37%;
	}

	&-spark-6 {
		left: 10.2%;
		top: 24%;
	}
}

}
