.c-article-preview-large {
  position: relative;
  background: $blue-gradient;
  padding: var(--space-s-m);
  display: flex;
  flex-direction: column;
  border-radius: var(--space-2xs) 0px 0px 0px;
  overflow: hidden;

  img {
    box-shadow: -5px -5px 20px 5px rgba(13, 36, 47, 0.5);
    border-radius: var(--space-2xs) 0px 0px 0px;
    aspect-ratio: 1.65;
    object-fit: cover;
    margin-bottom: -1rem;
  }
  
  figure {
    margin: auto calc(-1 * var(--space-s-m)) calc(-1 * var(--space-s-m)) var(--space-s);
    display: flex;
    justify-content: flex-end;
    padding-top: var(--space-s);
  }

  .anim-munch {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}