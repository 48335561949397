*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
}

body {
  margin: 0;
}

html:not(.no-smooth-scroll) {
  scroll-behavior: smooth;
}

blockquote,
pre,
ol,
ul,
figure {
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
  display: block;
  height: auto;
  border: none;
}

article,
aside,
figure,
footer,
header,
aside,
main,
nav {
  display: block;
}

iframe {
  border: none;
}

// Typography

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  font-size: 1em;
  margin: 0;
  text-wrap: balance;
}

a {
  color: inherit;
  text-decoration: none;
}

// Lists

ol,
ul {
  list-style: none;
}

// Font styles

cite,
address {
  font-style: normal;
}

// Form

[type='submit'],
[type='button'],
button {
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  text-align: inherit;
  -webkit-appearance: none;
  appearance: none;
}

[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

label {
  cursor: pointer;
}


@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    scroll-behavior: auto !important;
  }
}

// SVG biz for cassie

svg {
  overflow: visible;
}

svg * {
  will-change: transform;
}

main {
 z-index: 1;
}

aside {
 z-index: 2;
}