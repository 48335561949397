.c-categories {
  display: flex;
  gap: 0.5em;
  align-items: flex-start;

  svg {
    height: var(--step-4);
    width: var(--step-4);
    flex-shrink: 0;
  }

  a span {
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    position: relative;
    transition: transform 0.3s;
    color: color('white', 'step-1');
    text-decoration-color: color('primary');
  }

  ul {
    li {
      display: inline;
    }

    li:not(:last-child) a:after {
      content: ', ';
    }
  }
}
