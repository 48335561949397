.c-article {
  @media (min-width: 50em) {
    padding-top: 50em;
  }

  &__related {
    display: flex;
    gap: var(--space-xs);
    align-items: flex-end;

    > * {
      border-bottom: 2px solid color('mint', 'step--1');
    }

    figure {
      display: block;
      line-height: 0;

      svg {
        width: 81px;
        height: 52px;
      }
    }

    span {
      flex-grow: 1;
      padding-bottom: var(--space-2xs);
    }
  }

  &__image {
    background: color('neutral', 'step--3');
    padding: var(--space-s);
    border-radius: var(--space-2xs);

    > a {
      display: block;
    }
  }

  &__social {
    padding: var(--space-s-l) var(--space-s);
    text-align: center;

    ul {
      display: flex;
      justify-content: center;
      gap: var(--space-s);
      flex-wrap: wrap;
    }

    a {
      transition: 300ms filter;

      &:hover {
        filter: brightness(5);
      }
    }
  }

  &__beats {
    padding: var(--space-s-l) var(--space-s) 0;
    text-align: center;

    button {
      display: flex;
      justify-content: center;
      width: 100%;

      svg {
        width: 60%;
        z-index: 1;
        pointer-events: none;
      }
    }
  }
}

.o-article {
  a:not(.c-button) {
    text-decoration-color: var(--article-link, color('bright-blue'));
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    color: var(--article-link, color('bright-blue'));
  }
}

.o-article__content-column {
  padding-top: var(--space-s-l);
  word-break: break-word;
}

.o-article-page .c-header,
.o-articles-page .c-header,
.o-article__main {
  margin-bottom: 0;
}

.o-articles-page a:focus,
.o-articles-page a:active {
  box-shadow: none;
}

.c-toc {
  display: none;

  @media (min-width: 50em) {
    display: block;
  }
}

.toc {
  font-family: $font-stack-heading;
  font-size: var(--step-0);

  ol li + li {
    margin-top: var(--space-xs);
  }

  a {
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
    position: relative;
    transition: 300ms transform;
    display: block;
    color: color('white', 'step-1');
    text-decoration-color: #264b5d;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' fill='none'%3E%3Cg stroke='%23E6E6E6' stroke-linecap='round' stroke-linejoin='round' clip-path='url(%23a)'%3E%3Cpath d='m5.456 8.263 8.884 12.42M5.436 3.214C3.94 1.24 1.445.614.296 1.45L5.74 8.635c1.117-.88 1.19-3.45-.304-5.424v.003ZM13.882 6.92 3.778 21.055M15.492.295c-.042.05-1.811 2.52-1.847 2.57-1.016 1.421-.892 3.247.275 4.083 1.167.833 2.937.36 3.953-1.06.035-.051 1.799-2.524 1.834-2.58M17.598 1.802l-1.152 1.613M14.295 2.375l3.989 2.854'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v21.35H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-size: cover;
      width: 22px;
      height: 22px;
      position: absolute;
      margin-right: var(--space-xs);
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: 300ms opacity;
    }
  }

  [aria-current='step'] {
    color: color('primary');
    font-weight: weight('bold');
    text-decoration: none;
    transform: translateX(var(--space-m));

    &:before {
      opacity: 1;
    }
  }
}
