.c-flg {
  counter-increment: lesson;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-l) var(--space-xs);
  background: color('neutral', 'step--1');
  padding: var(--space-l-xl) var(--space-s-m);
  scroll-margin-top: 140px;

  & + & {
    margin-top: var(--space-l);
  }
  
  img {
    max-width: 290px;
  }

  &__number {
    text-align: center;

    &:after {
      content: counters(lesson, '', decimal-leading-zero);
    }
  }

  @media (min-width: 40em) {
    grid-template-columns: min(40%, 480px) min(50%, 608px);
    justify-content: space-between;
    align-items: center;
  }

  &__lesson {
    border-bottom: 1px solid rgba(color('white'), 0.4);

    [aria-controls] {
      padding: var(--space-xs) var(--space-xl) var(--space-xs) 0;
      width: 100%;
      position: relative;

      &:focus {
        outline-offset: 0px;
      }

      &:after,
      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        background: color('primary');
        height: 1.5px;
        border-radius: 2px;
        content: '';
        right: 0;
        transition: 300ms transform;
      }

      &[aria-expanded="false"]:before {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    figure {
      transition: 300ms height, 300ms padding;
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-xs);
      padding: 0;

      &[hidden] {
        height: 0;
        overflow: hidden;
      }
  
      &:not([hidden]) {
        height: auto;
        height: calc-size(auto);
        padding: var(--space-xs) 0;
      }
  

      img {
        max-width: 200px;
        flex-basis: 200px; 
        flex-grow: 1;
        align-self: flex-start;
        background: color('primary');
      }

      figcaption {
        flex-basis: 0;
        flex-grow: 999;
        min-inline-size: 50%;
      }
    }
  }
}
