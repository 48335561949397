.c-button {
  --button-v-padding: 0.777em;
  --button-h-padding: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-gradient;
  font-family: $font-stack-heading;
  font-weight: 700;
  font-size: var(--step-2);
  padding: var(--button-v-padding) var(--button-h-padding);
  transition: 100ms background-color, 100ms color, 100ms box-shadow;
  transition-timing-function: ease-in-out;
  letter-spacing: 0.2px;
  color: color('dynamic', 'on-primary');
  border-radius: var(--border-radius);
  text-shadow: $shadow;

  &:focus,
  &:hover {
    background-color: color('primary', 'step--1');
    text-decoration: none;
    color: color('dynamic', 'on-primary')!important;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  &--wide {
    width: 100%;
    padding-right: 0.75em;
    padding-left: 0.75em;
    text-align: center;
    white-space: normal;
  }

  &--small {
    border-radius: 0.25em;
    padding: 0.4em 1em;
  }

  &--w100 {
    width: 100%;
  }

  &--inline {
    display: inline-flex;
  }

  &--secondary {
    position: relative;
    z-index: 1;
    background: $blue-gradient-reverse;
    border: 2px solid $blue-gradient--border;
    color: color('dynamic', 'on-secondary');
    box-shadow: 0 2px 0 color('neutral', 'step--5');
    position: relative;
    top: -2px;
    transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &-border {
      border: 2px solid $blue-gradient--border;
    }

    &:hover {
      background: $button-hover-gradient;
      background-clip: padding-box;
      color: color('dynamic', 'on-secondary') !important;
      text-shadow: $text-shadow-hover;
      border: none;
      padding: calc(var(--button-v-padding) + 2px) calc(var(--button-h-padding) + 2px);
      box-shadow: #27073c 0px 1px;		
    }

    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }

    &:focus,
    &:active {
      background: $primary-gradient;
      color: color('dynamic', 'on-primary')!important;
      top: 3px;
      box-shadow: 0 4px 0 transparent;
	    text-shadow: $text-shadow-active;
    }
  }

  &--content {
    --button-h-padding: 1em;
    display: inline-block;
  }

  &--shadow {
    position: relative;
    top: -2px;
    transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &:focus,
    &:active {
      top: 3px;
      box-shadow: 0 4px 0 transparent;
	  color: color('dynamic', 'on-primary')!important;
    }
  }

  &--static-shadow {
    position: relative;
    top: -2px;
  }

  &--inline-flex {
    display: inline-flex;
  }

  &--shadow-blue {
    box-shadow: 0 4px 0 color('neutral', 'step--5');

    &-border {
      border: 1px solid $blue-gradient--border;
    }
  }

  &--shadow-orange {
    box-shadow: 0 4px 0 color('primary', 'step--4');
  }

  &--split {
    padding: 0;
    min-height: 60px;

    & > * {
      padding: 0.777em 0.25em;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        background-color: $primary-gradient;
        padding: 0 0.75rem;
        border-radius: 6px 0 0 6px;
        font-size: 2em;
        align-self: stretch;
      }

      &:last-child {
        // padding: 0.777em 1em;
        padding: 0.777em 1em 0.777em 0.25em;
	}
    }
  }

  &__caption {
    opacity: 0.75;
  }
}

.c-button-text {
  font-weight: 700;
  font-family: $font-stack-heading;
  color: color('primary');
  font-size: var(--step-4);
}

.c-button-circle {
  flex-basis: var(--space-l-xl);
  flex-shrink: 0;
  height: var(--space-l-xl);
  width: var(--space-l-xl);
  border-radius: 100%;
  //   background: $primary-gradient;
  background: $blue-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  //   transition: 300ms transform;

  &--ellipsis {
    background: transparent;
  }

  &:hover {
    // transform: scale(1.05);
    color: color('white');
    background: $button-hover-gradient;
  }

  &:active,
  &:focus {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  svg {
    height: var(--space-s);
    width: var(--space-s);
    background: none;
    border: none;
    padding: 0;
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.3));
  }
}
