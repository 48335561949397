// Layout
.o-grid {
  display: grid;
  grid-gap: var(--gutter);

  &--article-switch > :first-child {
    .c-sidebar-newsletter {
      display: none;
    }
  }

  @media (min-width: 50em) {
    &--article-switch,
    &--article {
      grid-template-columns: minmax(0, 2fr) 1fr;
    }

    &--article-switch > :first-child {
      order: 1;
      padding-left: var(--gutter);
      padding-block: var(--gutter);


      .c-sidebar-newsletter {
        display: block;
      }
    }

    &--article-switch > :last-child {
      .c-sidebar-newsletter {
        display: none;
      }
    }

    &--sticky.o-grid--article > :last-child > * > *,
    &--sticky.o-grid--article-switch > :first-child > * > * {
      position: sticky;
      top: 98px;
    }

    &--article-switch > :first-child,
    &--article > :last-child {
      border-left: 1px solid $border--dark-blue;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--gutter);

      > * {
        flex-grow: 1;
        
        &:last-child {
          flex-grow: 0.2;
        }
        
        .toc {
          border-left: 4px solid color('primary');
          margin-left: -1px;
          margin-block: var(--space-s-l);
        }

        figure {
          display: flex;
          align-items: flex-end;
          gap: var(--space-s);

          svg {
            width: 54px;
            height: 70px;
          }

          span {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: var(--step--1);
          }
        }

        
        figure,
        nav {
          padding-inline: var(--space-s);
        }
      }
    }
  }

  &--sidebar-bleed {
    position: relative;
    border-top: 1px solid $border;

    &:before {
      content: '';
      background: $border;
      position: absolute;
      height: 1px;
      width: var(--bleed-space);
      left: 100%;
      top: -1px;
    }
  }

  &--of-four {
    grid-template-columns: repeat(12, 1fr);

    & > * {
      grid-column: span 12;

        @media (min-width: 40em) {
            grid-column: span 6;
        }

        @media (min-width: 65em) {
            grid-column: span 3;
        }
    }
  }
}
