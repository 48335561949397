body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font: weight() #{1.25rem}/lh() $font-stack;
  font-size: var(--step-2);
  color: color('white');
  background: color('neutral');
}

.u-focus-fix:focus-visible,
.c-h a:focus-visible,
:focus {
  outline: 2px dashed color('primary');
  outline-offset: 10px;
  border-radius: var(--space-2xs);
  color: color('white', 'step-1') !important;
}

a {
	transition: box-shadow .2s ease-in-out
}
