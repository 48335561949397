.c-embed {
  &__wrapper {
    padding-bottom: 56.625%;
    position: relative;
    height: 0;

    & > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}