.c-article-preview-tiny {
  &__image {
    margin-bottom: var(--space-xs);

    img {
      object-fit: cover;
      border-radius: var(--space-2xs);
    }

    &--square img {
      aspect-ratio: 1 / 1;
    }

    &--landscape img {
      aspect-ratio: 26 / 16;
    }

    &--portrait img {
      aspect-ratio: 16 / 20;
    }
  }
}
