.c-header {
  --header-logo-primary: #{color('dynamic', 'logo')};
  --header-logo-secondary: #{color('white')};
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
  margin-bottom: var(--space-l-xl);
  background: color('neutral');
  display: flex;
  align-items: center;
  justify-content: stretch;

  .c-modal & {
    background: color('neutral');
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__wrap {
    position: relative;
  }

  &__logo-wrapper {
    width: 203px;
    display: flex;
    align-items: center;
    gap: var(--space-s-m);

    .c-header__logo {
      flex-grow: 1;
    }

    .c-button-circle {
      height: 2.25rem;
      width: 2.25rem;
      flex-basis: 2.25rem;
      display: none;
      
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;

    &-aspect {
      overflow: hidden;
      position: relative;
      height: 0;
    }

    &-smash {
      max-width: 53px;
      width: 21.5%;
      position: relative;
    }

    &-smash &-aspect {
      padding-bottom: 127%;
      position: absolute;
      width: 100%;
      left: 0;

      svg {
        height: 128.39%;
      }
    }

    &-smart {
      max-width: 178px;
      width: 73.251%;
    }

    &-smart &-aspect {
      padding-bottom: 28.873%;
    }

    svg {
      width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__cta {
    display: none;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
    margin-top: calc(-1 * var(--space-2xs));
    margin-bottom: calc(-1 * var(--space-2xs));
    background: $primary-gradient;
    border-radius: var(--border-radius);
    padding: 1rem var(--space-s);
    line-height: 1.2;
    text-align: center;
    font-size: var(--step-0);
    font-family: $font-stack-heading;
    text-shadow: $shadow;
    color: color('dynamic', 'on-primary');
    max-width: 210px;
    align-self: stretch;
    opacity: 0;
    transition: 300ms opacity;

	.c-mobile-cta {
		text-shadow: $text-shadow-divider;
	}
	
    &:focus {
      outline-offset: 5px;
      color: color('dynamic', 'on-primary')!important;
    }

    small {
      font-size: var(--step-0);
    }

    .c-icon {
      width: 32px;
      height: 39px;
      margin-right: var(--space-s);
      display: none;

      &.c-icon--header-nav-tube {
        margin-right: var(--space-2xs);
        width: 48px;
        height: 48px;
      }
    }
  }

  &__siblings,
  &__nav-siblings,
  &__nav-siblings-trigger {
    display: none;
  }

  @media (max-width: 799px) {
    &__nav {
    //   background: $nav-gradient;
	  background: $header-gradient;
      position: absolute;
      width: 100%;
      left: 0;
      top: 145%;
      padding: var(--gutter);
      font-family: $font-stack-heading;
      display: none;
      z-index: 100;
      font-size: var(--step-1);
      text-shadow: $text-shadow-divider;
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      color: color('dynamic', 'on-secondary');
	  
	.c-mobile-cta {
		color: color('dynamic', 'on-secondary');
	}

      button:focus,
      a:focus {
        color: color('dynamic', 'on-secondary') !important;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--space-s) var(--space-2xs);

        & > * {
        //   border-bottom: 1px solid $header-sibling-border;
          padding-bottom: var(--space-xss);
        }

        li:last-child:nth-child(odd) {
          border-bottom: none;
          padding-bottom: 0;
        //   grid-column: span 2;
        //   justify-content: center;
          display: flex;
          align-items: center;
        }

        & > a {
          padding-top: var(--space-xs);
          font-weight: bold;
          text-shadow: 1px 1px 1px rgba(0 0 0 / 30%);
        }

        a {
          display: flex;
          align-items: center;

          &:focus,
          &:active {
            box-shadow: none;
            outline-color: color('white', 'step-1');
          }

          & > :first-child {
            width: 35px;
            height: 25px;
          }

          span:first-child svg {
            width: 1.5rem;
            height: 1.5rem;
          }

          span:last-child svg {
            width: 1em;
            height: 1em;
            display: inline-block;
            position: relative;
            top: 0.1em;
          }
        }
      }
    }

    &:target {
      background: $header-gradient;
      --header-logo-primary: #{color('dynamic', 'on-secondary')};
      --header-logo-secondary: #{color('dynamic', 'on-secondary')};
      --smashing-logo: #{color('dynamic', 'logo-open')};
      position: sticky;
      z-index: 50;
      top: 0;
    }

    &:target &__nav {
      display: block;
    }

    &:target .c-navicon {
      background: color('white');
      z-index: 200;

      .c-icon {
        opacity: 0;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 22px;
        height: 1px;
        background: color('dynamic', 'navicon');
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

      &:before {
        height: 22px;
        width: 1px;
      }
    }

    &__nav-siblings {
      margin-top: var(--gutter);
      margin-bottom: var(--negative-gutter);

	  /* Workaround for full width background */
	  width: calc(100% + var(--gutter)* 2);
	  margin-left: calc(-1* var(--gutter));
	  padding: 0 var(--gutter);
	  background: $header-mobile-more-courses;
	  /* Workaround ending */

	  
	.siblings__byline {
		display: block;
		font-weight: 400;
	}
	
      &:not([hidden]) {
        display: block;
      }

      a {
        padding: var(--space-s) 0;
      }

      li + li {
        border-top: 1px solid $header-sibling-border;
      }

      &-trigger {
        padding: var(--space-s) var(--gutter);
        margin: 0 var(--negative-gutter) var(--negative-gutter);
        display: flex;
        width: calc(100% + var(--gutter) * 2);
        text-align: center;
        font-size: var(--step-0);
		background: $header-mobile-more-courses;
		text-shadow: $text-shadow-divider; 
		border-top: 1px solid rgba(0, 0, 0, .3);

        &:focus {
          outline-offset: 0px;
        //   outline-color: color('dynamic', 'on-primary');
        }

        .c-twi {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .c-icon {
          font-size: 1em;
        }

        &[aria-expanded="true"] svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  @media (max-width: 350px) {
    &__nav {
      font-size: var(--step-0);
    }
  }

  @media (min-width: 800px) {
    transition: 300ms background;
    margin-top: var(--space-2xs);
    padding-top: var(--space-s);
    height: 98px;
    position: sticky;
    z-index: 50;
    top: 0;

    .c-modal & {
      padding-bottom: var(--space-m);
      padding-top: var(--space-m);
    }

    .is-sticky &--sticky {
      background-color: rgba(color('neutral', 'step--3'), 74%);
      z-index: 50;
      --header-logo-primary: #{color('primary')};
      --header-logo-secondary: #{color('white')};
      animation: 1s headerIn;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    }

    &__nav {
      font-family: $font-stack-heading;
      font-size: var(--step-0);
      font-weight: 500;

      ul {
        display: flex;
        padding-left: var(--space-s);

        & > * + * {
          margin-left: var(--space-s);
        }

        svg {
          display: none;
        }

        a:hover {
          text-decoration: underline;
        }

        a {
          &.active {
            text-decoration: underline;
            text-decoration-color: color('primary');
            text-decoration-thickness: 2px;
            text-underline-offset: 4px;
          }

          span:last-child svg {
            width: 1em;
            height: 1em;
            display: inline-block;
            margin-left: 0.25em;
            position: relative;
            top: 0.1em;
          }
        }
      }
    }

    &__logo-wrapper {
      max-width: calc(248px + var(--space-l) + var(--space-s));
      width: 27%;
      position: relative;

      .c-button-circle {
        display: flex;
        background: $primary-gradient;
        color: color('dynamic', 'on-primary');
        
        svg {
          filter: none;
        }

        &:active,
        &:focus {
          color: color('dynamic', 'on-primary')!important;
          top: 0;
        }
      }

      &--open {
        --header-logo-primary: #{color('dynamic', 'on-secondary')};
        --header-logo-secondary: #{color('dynamic', 'on-secondary')};
        --smashing-logo: #{color('dynamic', 'logo-open')};

        &:before {
          content: '';
          left: calc(-1 * var(--space-xs));
          border-radius: var(--space-2xs) var(--space-2xs) 0 0;
          background: $header-sibling-nav;
          right: calc(-1 * var(--space-xs));
          top: calc(-1 * var(--space-xs));
          bottom: 0;
          position: absolute;
          height: 200%;
        }

        .c-button-circle {
          box-shadow: none;
          transform: rotate(-180deg);
          display: flex;
          background: $header-sibling-trigger;
          outline: none;
          
          svg {
            color: color('dynamic', 'header-sibling-chevron');
          }
        }
      }
    }

    &__siblings {
      position: absolute;
      left: calc(-1 * var(--space-xs));
      top: 100%;
      background: color('primary', 'step-0');
      color: color('white');
      border-radius: 0 0 var(--space-2xs) var(--space-2xs);
      font-family: $font-stack-heading;
      font-weight: 700;
      font-size: var(--step-0);
      min-width: calc(100% + 2 * var(--space-xs));
      box-shadow: 0 10px 10px rgba(0,0,0,0.3);

      position: absolute;
      top: 130%;
      text-shadow: $shadow;
      overflow: hidden;
	  background: $header-sibling-nav-siblings;
	
      &:not([hidden]) {
        display: block;
      }

      .c-icon {
        font-size: 1.5rem;
      }

      a {
        display: flex;
        padding: var(--space-xs) var(--space-2xs) var(--space-xs) var(--space-xs);
		text-shadow: $text-shadow-divider;

        &:focus {
          outline-offset: 0px;
          outline-color: color('dynamic', 'on-secondary')!important;
		  border-radius: 0;
          color: color('dynamic', 'on-secondary')!important;
        }

        // &:hover {
        //   background: $header-sibling-hover;
        // }
      }

      li {
        border-top: 1px solid $header-sibling-border;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
	
	.siblings__byline {
		display: block;
		font-weight: 400;
	}
  }

  @media (min-width: 1000px) {
    &__nav {
      font-size: var(--step-1);
    }
  }

  &__nav {
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    &__nav {
      padding-left: var(--space-m);
      white-space: nowrap;

      ul {
        & > * + * {
          margin-left: var(--space-m);
        }
      }
    }
  }

  @media (min-width: 1224px) {
    &__cta {
      display: flex;
    }

    .is-sticky &__cta {
      opacity: 1;
      transition-delay: 150ms;
    }

    &__nav {
      margin-left: auto;
      padding-left: var(--space-s);
      padding-right: var(--space-m);
      font-size: var(--step-0);
    }
  }

  @media (min-width: 1324px) {
    &__cta {
      display: flex;
      max-width: 270px;
      font-size: var(--step-1);

      .c-icon {
        display: block;
      }
    }

    &__nav {
      font-size: var(--step-1);
    }
  }
}

.c-navicon {
  position: fixed;
  right: var(--gutter);
  top: calc(var(--space-s) + 4px);
  background: $primary-gradient;
  color: color('dynamic', 'on-primary');
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  z-index: 100;

  &--shadow {
    top: calc(var(--space-s) + 4px - 2px);
    transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &:active,
    &:focus {
      top: calc(var(--space-s) + 4px + 3px);
    }
  }

  .c-icon {
    font-size: 1.25em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (min-width: 800px) {
    display: none;
  }

  .c-header:target &--open {
    display: none;
  }

  .c-header:not(:target) &--close {
    display: none;
  }
}

@media (min-width: 48em) {
  [id] {
    scroll-margin-top: 140px;
  }

  #packages {
    scroll-margin-top: 30px;
  }

  #about-live-training,
  #testimonials {
    scroll-margin-top: 50px;
  }
}
  
.c-mobile-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-xs) var(--gutter);
  margin: var(--space-xs-s) var(--negative-gutter) 0;
  font-family: $font-stack-heading;
  color: color('dynamic', 'on-primary');
//   background: $header-mobile-cta;
  border-top: 1px solid #00000059;
  border-radius: 0;

  
  
  &:focus, &:active {
	outline-color: #fff;
  }

  &--fixed {
    position: fixed;
    z-index: 50;
    bottom: 0;
    left: 0;
    margin: 0;

    width: calc(100% - 2 * var(--space-s));
    left: 0;
    margin: var(--space-xs) var(--space-s);
    border-radius: var(--border-radius);
    background: $primary-gradient;

    body.o-article-page &,
    body.o-articles-page & {
      display: none;
    }
  }

  small {
    font-size: var(--step-0);
  }

  .c-header:target + & {
    display: none;
  }

  & > :first-child {
    flex-basis: 32px;
    margin-right: var(--space-m);
  }

  & > :last-child {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    min-width: 200px;
  }

  svg {
    width: 32px;
    height: 39px;
  }

  @media (min-width: 800px) {
    display: none;
  }
}
