.c-packages__list {
  & > * {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    & + * {
      margin-top: var(--gutter);
    }
  }

  @media (min-width: 40em) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: var(--gutter);

    & > * {
      flex-basis: 362px;
      margin-left: 0;
      margin-right: 0;

      & + * {
        margin-top: 0;
      }

      &:nth-child(3) {
        flex-basis: 765px;
        flex-grow: 1;
        max-width: 765px;
      }
    }
  }
}

.c-package {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.2);
  padding: var(--space-s-l);
  text-align: center;
  flex-basis: 362px;
  position: relative;
  transition: 300ms transform, 300ms box-shadow;
  will-change: transform;
  z-index: 2;

  a:has(header) {
    &:focus {
      display: block;
    }
  }

  &__price {
    color: color('primary', 'step-2');
  }

  & > * + * {
    margin-top: var(--space-m-l);
  }

  &--double {
    margin-top: 160px;

    header > svg {
      margin-top: -160px;
      max-width: 100%;
    }
  }

  &__badge {
    // background: rgba(color('white'), 0.2);
    background: $primary-gradient;
    border-radius: 0.5rem;
    color: color('dynamic', 'on-primary');
    display: inline-block;
    font-size: var(--step-2);
    padding: var(--space-3xs) var(--space-xs);
    text-shadow: $badge-text-shadow;
    // font-style: italic;
    // font-weight: 400;
    margin-left: var(--space-2xs);
  }

  @media (prefers-reduced-motion: no-preference) {
    &:hover {
      transform: scale(1.005);
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
    }
  }

  &__subtitle {
    border-radius: 6px;
    background-image: $blue-gradient-reverse;
    display: inline-flex;
    margin: 0 auto;
    padding: var(--space-2xs) var(--space-xs);

    &:hover {
      background: $button-hover-gradient;
      background-clip: padding-box;
      color: color('dynamic', 'on-secondary') !important;
      text-shadow: $text-shadow-hover;
      border: none;
      padding: calc(var(--space-2xs) + 1px) calc(var(--space-xs) + 1px);
      box-shadow: #27073c 0px 1px;
    }

    // &:active, &:focus {
    // 	outline: 2px dashed color('primary');
    // 	outline-offset: 10px;
    // 	border-radius: var(--space-2xs);
    // 	color: color('white', 'step-1') !important;
    // }
  }

  @media (min-width: 40em) {
    &:nth-child(3) &__subtitles {
      display: flex;
      gap: var(--space-2xs);
      align-items: center;
      justify-content: center;
    }

    &:nth-child(3) &__button {
      display: inline-flex;
    }
  }

  @media (min-width: 45em) {
    &:nth-child(3) .o-body {
      display: flex;
      gap: var(--space-m);
      justify-content: center;

      > * + * {
        margin-top: 0;
      }
    }
  }

  &__button {
    background: $primary-gradient;
    display: flex;
    font-family: $font-stack-heading;
    border-radius: var(--border-radius);
    justify-content: space-between;
    text-align: left;
    text-shadow: $shadow;
    padding: var(--space-2xs);
    color: color('dynamic', 'on-primary');
    margin-top: var(--space-xs);

    * {
      display: block;
    }

    &-cost {
      border-radius: 0 6px 6px 0;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      order: 2;
      padding-right: var(--space-xs);
      padding-left: var(--space-xs);
    }

    &-promo {
      display: flex;
      flex-direction: column;
    }

    &-cost-upcoming {
      position: relative;
      color: rgba(13 37 46 / 90%);
      text-shadow: none;
      font-size: 0.9em;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 11px;
        background: color('neutral');
        top: 50%;
        margin-top: -1px;
        transform: rotate(-30deg);
      }
    }

    &-specs {
      padding: var(--space-2xs) var(--space-xs);
    }
  }
}

.c-packages {
  .c-icon {
    position: absolute;
  }

  .c-packages-icon {
    &-1 {
      font-size: 2.2em;
      left: 10%;
      top: 10%;
    }

    &-2 {
      left: 15%;
      top: 50%;
    }

    &-3 {
      left: 10%;
      top: 70%;
    }

    &-4 {
      right: 30%;
      top: -2%;
      font-size: 2.4em;
      transform: rotate(30deg);
    }

    &-5 {
      right: 18%;
      top: -20%;
    }

    &-6 {
      right: 5%;
      top: 50%;
    }

    &-7 {
      right: 16%;
      top: 70%;
    }
  }
}
