.u-flow {
  &--3xs {
    & > * + * {
      margin-top: var(--space-3xs);
    }
  }

  &--2xs {
    & > * + * {
      margin-top: var(--space-2xs);
    }
  }

  &--xs {
    & > * + * {
      margin-top: var(--space-xs);
    }
  }

  &--s,
  & {
    & > * + * {
      margin-top: var(--space-xs-s);
    }
  }

  &--m {
    & > * + * {
      margin-top: var(--space-m);
    }
  }

  &--l {
    & > * + * {
      margin-top: var(--space-l);
    }
  }

  &--gutter {
    & > * + * {
      margin-top: var(--gutter);
    }
  }

  &--prose > * + * {
    margin-top: var(--flow, 1em);
  }

  &-x {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-s);
  }
}