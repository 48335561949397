.u-flex {
  display: flex;
}

.u-flex-column {
  flex-direction: column;
}

.u-align-center {
  align-items: center;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-no-shrink {
  flex-shrink: 0;
}

.u-flex-no-grow {
  flex-grow: 0;
}

.u-flex-wrap {
  flex-wrap: wrap;
}
