.c-article-cta {
  position: relative;
  margin: var(--space-xl-2xl) 0;

  &__background {
    background: $blue-gradient;
    padding: var(--space-m-l);
    padding-top: 130px;
    border-radius: var(--space-2xs);
  }

  &__eyebrow {
    opacity: 0.3;
    font-size: var(--step--2);
    font-family: $font-stack-heading;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    display: block;
    margin-bottom: var(--space-2xs);
    padding-right: 160px;
  }

  .anim-bite {
    position: absolute;
    right: 0;
    top: 0;
    height: 130px;
    overflow: visible;

    .mobile-munch {
      opacity: 1;
    }

    .desktop-munch {
      opacity: 0;
    }
  }

  @media (min-width: 600px) {
    &__background {
      background: $blue-gradient;
      padding: var(--space-m-l);
      padding-right: 180px;
    }

    .anim-bite {
      height: 140px;

      .mobile-munch {
        opacity: 0;
      }

      .desktop-munch {
        opacity: 1;
      }
    }
  }
}
