.c-embed {
  &__wrapper {
    padding-bottom: 56.625%;
    position: relative;
    height: 0;

    & > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    color: white;
    z-index: 10;

    p {
      margin-bottom: 20px;
      max-width: 80%;
      line-height: 1.5;

      a {
        transition: opacity 0.2s ease;
      }
    }
  }
}