:root {
  --wrapper: 1200px;
  --wrapper-with-gutters: calc(var(--wrapper) + var(--gutter) + var(--gutter));
}

.u-wrap {
  padding-right: var(--gutter);
  padding-left: var(--gutter);
  margin-right: auto;
  margin-left: auto;
  max-width: var(--wrapper-with-gutters);
  width: 100%;

  .u-wrap {
    padding-right: 0;
    padding-left: 0;
  }

  &--content {
    max-width: 51.75rem;
    width: 100%;
  }

  &--full {
    max-width: 1440px;
  }
}
