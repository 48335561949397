.o-body {
  font-size: var(--step-1);

  & > * + * {
    margin-top: 1em;
  }
}

.o-prose {
	font-size: var(--step-3);
	
  & > * + * {
    margin-top: 1em;
  }
}

.o-body,
.o-prose {
  color: inherit;

  h2 {
    @extend .c-h,.c-h-3;
	
	.header-anchor {
			--rainbow-gradient: linear-gradient(-215deg,#fbbebc,#ff6a66 43%,#7fb3ff 60%,#35c3ff);
			background-image: var(--rainbow-gradient,#{color('white', 'step-1')});
			background-size: 100%;
			background-repeat: repeat;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-background-clip: text;
			-moz-text-fill-color: transparent;
			filter: drop-shadow(0 0 2rem #000);
			text-shadow: none;
			letter-spacing: 0;
			font-size: 0.85em;
	}
  }

  h3 {
    @extend .c-h,.c-h-2;
  }

  h4 {
    @extend .c-h,.c-h-1;
  }

  p,
  ul,
  ol {
    line-height: lh('prose');
  }

  p a:not(.c-button),
  li a:not(.c-button) {
    color: color('primary');  
  
    &:hover {
      text-decoration: underline;
	  text-decoration-skip-ink: auto;
	  text-decoration-thickness: 1px;
    //   text-underline-offset: 4px;
	  text-decoration-color: color('white');
    }
  }

  ul li {
    list-style: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20" viewBox="0 0 10 20"><circle cx="5" cy="14" r="3.5" fill="%23ff6855" fill-rule="evenodd"/></svg>') outside;    
    margin-left: 1em;
    padding-left: 0.5rem;
  }
  
  ul.measure-ux li {
    list-style: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20" viewBox="0 0 10 20"><circle cx="5" cy="14" r="3.5" fill="%23bfe6c7" fill-rule="evenodd"/></svg>') outside;    
  }

  ol li {
    list-style: decimal;
    padding-left: 0.375rem;
    margin-left: 1.125em;
  }

  ol ol li {
    list-style: lower-alpha;
  }

  figcaption {
    font-size: var(--step--1);
    display: block;
    font-family: $font-stack-heading;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    padding-left: calc(var(--article-inset, var(--space-xl)) - var(--space-s));
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cg stroke='%23FFF8EC' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' opacity='.8'%3E%3Cpath d='M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2v11Z'/%3E%3Cpath d='M12 17a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left center;
  }
  
  // Spacing

  ol,
  ul {
    ul,
    ol {
      margin-top: var(--space-s);
    }

    li + li,
    li + li {
      margin-top: var(--space-2xs);
    }
  }

  ol,
  ul,
  & > pre {
    --flow: var(--space-s-m);
    margin-bottom: var(--flow);
  }

  h2,
  h3 {
    --flow: var(--space-m-l);
  }

  h2 {
    margin-bottom: var(--space-s-m);
  }

  figure,
  & > img {
    --flow: var(--space-l-xl);
    margin-bottom: var(--flow);
  }

  blockquote {
    --flow: var(--space-m-l);
    margin-bottom: var(--flow);
  }

  // Modifiers

  &--white-links {
    p a:not(.c-button),
    li a:not(.c-button) {
      color: color('white');
      font-weight: 700;
	  text-decoration: underline;
	  text-decoration-skip-ink: auto;
	  text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }
  }

  &--blue-links {
    p a:not(.c-button),
    li a:not(.c-button) {
      color: var(--article-link, color('bright-blue'));
      font-weight: var(--article-link-weight, 700);
      text-decoration: underline;
      text-decoration-skip-ink: auto;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;

// Vitaly's change
//text-underline-offset: 2px;
  
	  &:hover {
		color: color('white');
		text-decoration-color: #fb6654;
		text-decoration-thickness: 2px;
	  }
    }
  }
 
  &--primary-links {
    p a:not(.c-button),
    li a:not(.c-button) {
      color: color('white');
      font-weight: 700;
	  font-size: 0.95em;
	  text-decoration: underline;
	  text-decoration-skip-ink: auto;
	  text-decoration-color: color('primary');
	  text-decoration-thickness: 1px;
      text-underline-offset: 4px;
	  font-family: $font-stack-heading;
    }
  }

  &--list {
	  text-align: left;
  }

  &--no-list {
    ul,
    ol {
      li {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}

.o-featured-image {
  margin-left: calc(-1 * var(--gutter));
}

@media (min-width: 60em) {
	.o-prose figcaption {		
		padding-left: calc(var(--article-inset, var(--space-xl)) - var(--space-xl));		
	}
}

@media (min-width: 60em) {
  :root {
    --article-inset: calc(var(--space-2xl) + var(--space-s));
  }
}

.o-article.o-prose {
  font-size: var(--step-2);

  h2 {
    font-size: var(--step-6);
    margin-top: var(--space-l-xl);
    margin-bottom: 0;
    line-height: 1.13;
  }

  h3 {
    font-size: var(--step-5);
    margin-top: var(--space-l-xl);
    margin-bottom: 0;
    line-height: 1.13;
  }

  h4 {
    font-size: var(--step8-4);
    margin-top: var(--space-m-l);
    margin-bottom: 0;
    line-height: 1.13;
  }

  ol li,
  ul li {
    margin-left: var(--space-m);
    padding-left: calc(var(--space-m) + var(--space-3xs));
    background-repeat: no-repeat;
    background-position: left 0.3em;
  }

  ul li {
    list-style: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23FFE895' stroke='%23F4B67F' stroke-linecap='round' stroke-linejoin='round' d='M14.796 8.72a2.378 2.378 0 0 0 .572-2.45 2.362 2.362 0 0 0-1.948-1.596l-1.987-.261a.625.625 0 0 1-.452-.321l-.903-1.787A2.37 2.37 0 0 0 7.92 1a2.362 2.362 0 0 0-2.118 1.355S4.688 4.463 4.497 4.493l-1.978.311A2.367 2.367 0 0 0 .612 6.451a2.376 2.376 0 0 0 .633 2.439l1.445 1.375c.14.13.21.331.18.522l-.31 1.977c-.141.904.23 1.797.973 2.319.422.301.914.442 1.406.442.38 0 .762-.1 1.104-.292l1.756-.953c.17-.09.382-.1.552 0l1.787.913a2.389 2.389 0 0 0 2.51-.21 2.375 2.375 0 0 0 .923-2.34l-.361-1.967a.612.612 0 0 1 .16-.532l1.415-1.415.01-.01Z'/%3E%3Cpath stroke='%23F4B67F' stroke-linecap='round' stroke-linejoin='round' stroke-width='.5' d='M4.497 4.493s-.733 3.945 8.883 5.641'/%3E%3C/svg%3E");
  }

  ol li {
    margin-left: calc(var(--space-m) + var(--space-xs));
    padding-left: var(--space-2xs);
  }

  ol ::marker {
    color: color('primary');
  }

  &--list-mushroom ul li {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none'%3E%3Cpath fill='%239683BA' stroke='%23C9BDE0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6.65 2.041c-3.24.63-5.513 3.472-5.53 6.777-.01 2.457 1.48 1.495 3.697.791a.823.823 0 0 1 1.055.954l-.469 2.201c-.101.474-.099.978.072 1.436a2.585 2.585 0 0 0 4.757.19c.207-.443.25-.944.189-1.426l-.272-2.079a.822.822 0 0 1 1.124-.868c2.14.866 3.638 1.697 3.742-.89C15.19 4.74 11.26 1.14 6.65 2.036v.005Z'/%3E%3Cpath stroke='%23C9BDE0' stroke-miterlimit='10' d='M5.707 11.842s2.534-8.444 8.902-5.316'/%3E%3C/svg%3E");
  }

  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p,
  > ol,
  > ul {
    padding-left: var(--article-inset);
  }

  figure {
    clear: left;
  }

  blockquote {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none'%3E%3Cg stroke='%23FF6855' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' clip-path='url(%23a)'%3E%3Cpath d='M15.397 14.755c0-5.044 3.478-10.416 3.478-10.416s3.477 5.362 3.477 10.416c0 5.055-3.477 7.855-3.477 7.855s-3.478-2.81-3.478-7.854ZM21.034.993l-2.15 2.81-2.169-2.81M22.548 15.113l1.475-2.016M15.22 15.113l-1.474-2.016M9.603 9.245c0 5.044-3.478 10.416-3.478 10.416S2.648 14.299 2.648 9.245c0-5.055 3.477-7.865 3.477-7.865s3.478 2.81 3.478 7.855v.01ZM3.966 23.007l2.15-2.81 2.168 2.81M2.452 8.887.977 10.903M9.77 8.887l1.475 2.016'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h25v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    padding-top: var(--space-m);
    background-repeat: no-repeat;
    font-family: $font-stack-heading;
    font-size: var(--step-4);
    font-weight: 700;
    line-height: 1.2;
    
    @media (min-width: 40em) {
      float: left;
      width: 240px;
      max-width: 45%;
      margin-right: var(--gutter);
      margin-bottom: var(--space-xs);
    }
  }
}

// .u-article-lede {
//   padding-left: var(--article-inset);
// }

hr {
  border: 0;
  border-bottom: 4px solid $border--dark-blue;
  margin: var(--space-l) 0;
}
