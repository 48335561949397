.c-article-recommendations {
  &__list {
    @media (min-width: 70em) {
      padding-top: var(--space-s-l);
    }
  }

  @media (max-width: 50em) {
    > :last-child {
      display: none;
    }
  }
}