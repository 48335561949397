.c-pagination {
  ol {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-2xs);
  }

  .c-button-circle {
    // color: color('primary');
    // background: color('neutral', 'step--3');
    font-weight: 700;
    font-size: var(--step-1);
    font-family: $font-stack-heading;
	text-shadow: 1px 1px 1px rgba(0,0,0,.3);

    &[aria-current="page"] {
      background: $primary-gradient;
      color: color('white');
	  
	  &:hover {
		background: $primary-gradient;
	  }
    }

    &:hover {
      transform: none;
    //   background: $primary-gradient;
	  background: $button-hover-gradient;
      color: color('white');
    }
	
  }
}
