@each $name, $colour in $colours {
  .u-#{$name} { color: color($name); }
  .u-bg-#{$name} { background-color: color($name); }
  @each $tone, $shade in $colour {
    @if ($tone != 'step-0') {
      .u-#{$name}-#{$tone} { color: color($name, $tone); }
      .u-bg-#{$name}-#{$tone} { background-color: color($name, $tone); }
    }
  }
}
