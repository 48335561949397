.c-course-features {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  padding-top: var(--space-xl-2xl);

  &__image {
    margin-right: var(--negative-gutter);
    margin-left: var(--negative-gutter);
  }

  @media (min-width: 40em) {
    grid-template-columns: 7fr 0fr 4fr;
  }

  @media (min-width: 60em) {
    grid-template-columns: 7fr 1fr 4fr;
  }

  @media (min-width: 1272px) {
    &__image {
      margin-left: calc(-1 * ((100vw - 1200px) / 2));
      text-align: right;

      svg {
        max-width: 830px;
      }
    }
  }

  @media (min-width: 1600px) {
    &__image {
      margin-left: -200px;
    }
  }
}
