.c-icon-wrapper,
.c-icon-wrapper svg,
.c-icon {
  width: 1em;
  height: 1em;
  color: inherit;
  font-size: inherit;
}

.c-icon--arrow-left,
.c-icon--arrow-right {
  padding: 0.4em;
  width: 1.85em;
  height: 1.85em;
  border: 1px solid color('primary', 'step-2');
  color: color('dynamic', 'on-primary');
  border-radius: 50%;
  background: $primary-gradient;
}

.c-icon--shadow {
  box-shadow: 0 3px 0 color('primary', 'step--4');
  position: relative;
  top: -2px;
  transition: 0.1s all ease-in-out;

  &:active,
  &:focus {
    position: relative;
    top: 3px;
  }
}
