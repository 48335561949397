.c-footer {
  .c-input-inline-button {
    max-width: 666px;
  }

  &__border {
    border: none;
    margin-bottom: var(--space-l-xl);
    line-height: 0;
    position: relative;
    
    &:after {
      content: '';
      border-bottom: 2px solid color('mint', 'step--1');
      position: absolute;
      bottom: 0;
      right: 0;
      left: 23.5%;
    }
  }

  &__primary {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-l-xl) var(--gutter);

    @media (min-width: 48em) {
      grid-template-columns: 7fr 5fr;
    }

    @media (min-width: 60em) {
      grid-template-columns: 8fr 4fr;
    }
  }

  &__secondary {
    margin-top: var(--space-xl-2xl);
    text-align: center;
    font-family: $font-stack-heading;
    padding-bottom: var(--space-m-l);
    font-size: var(--step--1);
    color: rgba(color('white'), 0.5);
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: var(--space-m-l);
    }

    a {
      text-decoration: underline;
      transition: 300ms color;

      &:hover {
        color: color('white');
      }
    }

    img {
      max-width: 134px;
      margin: var(--space-s) auto 0;
      opacity: 0.7;
    }

    ul {
      display: flex;
      justify-content: center;

      & > * + * {
        margin-left: var(--space-m-l);
      }
    }

    @media (min-width: 48em) {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--space-s);

      & > * + * {
        margin-top: 0;
      }

      & > :last-child {
        display: flex;
        align-items: center;

        img {
          margin: 0 0 calc(-1 * var(--space-s)) var(--space-xs);
        }
      }

      ul {
        justify-content: flex-start;
        margin: var(--space-xs) 0 0;
      }
    }

    @media (min-width: 65em) {
      & > :first-child {
        display: flex;
        align-items: center;
      }

      ul {
        margin: 0 0 0 var(--space-m-l);
      }
    }
  }

}