.c-testimonials {
  &__wrapper {
    display: block;
    text-align: center;
    background-size: 100% 0.06em;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0;
    background-image: linear-gradient(
      45deg,
      rgba(71, 109, 166, 0.3),
      rgba(71, 109, 166, 0.5) 40%,
      rgba(71, 109, 166, 0.5) 55%,
      rgba(71, 109, 166, 0.3) 100%
    );
  }

  &__quote {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
    transition: max-height 0.2s ease;
    transition: opacity 0.5s ease;
  }

  &__quote--hidden {
    opacity: 0;
    max-height: 0;
    transition: 0.05s ease;
    pointer-events: none;
  }

  &__quotes {
    display: flex;
    flex-wrap: wrap;

    & > * + * {
      margin-top: var(--space-xl-2xl);
    }

    @media (min-width: 40em) {
      padding-bottom: calc(2 * var(--negative-gutter));
      justify-content: center;

      & > * + * {
        margin-top: 0;
      }

      & > *:not(.c-testimonials__quote--hidden) {
        margin-left: var(--gutter);
        width: calc(50% - var(--gutter));
        margin-bottom: calc(2 * var(--gutter));

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }

    @media (min-width: 60em) {
      & > *:not(.c-testimonials__quote--hidden) {
        width: calc(33.333% - (var(--gutter)));

        &:nth-child(2n + 1) {
          margin-left: var(--gutter);
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(4n + 1):nth-last-child(4),
        &:nth-child(4n + 1):nth-last-child(4) ~ * {
          width: calc(50% - (var(--gutter)));

          &:nth-child(3n + 1) {
            margin-left: var(--gutter);
          }

          &:nth-child(2n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.c-testimonial {
  a,
  cite {
    display: block;
  }

  &-byline {
    //     background-image: linear-gradient(
    // 45deg, rgb(251 105 85), rgb(255 69 69));
    display: inline-block;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
    padding: 0.1em 0.35em;
    box-shadow: 0 0 32px rgb(0 0 0 / 15%);
    /* margin: 0 -0.4em; */
    padding: 0.3em 0.6em;
    border-radius: 0.8em 0.3em;
    // background: transparent;
    // background-image: linear-gradient( to right, rgba(251, 105, 85, 0.7), rgba(255, 69, 69, 0.9) );
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background: 0 0;
    //     background-image: linear-gradient(
    // 45deg,rgb(65 128 158 / 70%),rgb(255 69 69 / 90%));
    background-image: linear-gradient(
      45deg,
      rgb(71 109 166 / 70%) 0,
      rgb(254 73 70 / 75%) 65%
    );
  }

  @media (min-width: 40em) {
    padding-right: var(--gutter);
  }

  img {
    width: 105px;
    border-radius: var(--border-radius);
    transform-origin: 0 100%;
    transform: rotate(-15deg);
    transition: 300ms transform;
  }

  figure {
    position: relative;

    &:after {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAAASCAYAAABim40OAAACuElEQVRogc1XUZJjIQjEzN7/yMl8ucP0dkNjXrKh6pWAqERaICveR+vD96vo8cazOvoYX54NwOl6Z52ycc907E4C4a45DfKrwHH5vpPgd7aTYKNOyUvYKL7zRZG6WNRP5cne7pxrMwHLEbCqS3aDMglwpcvjMmTGVz4wejQ86lDfjZ0OeSY/az/VMaJ2XQZA/QQQChSKV99N8OpjvuHvUsGt+ErX2bI9q7Hy0dE580pnZ0R1uTi/eSVX2SLrbmKeAeQG34qIL2KDozo/kwosBv4Ouk5WOheMyrfK5t1Z8K++Ak9XVjK/ZRW8LnMgYDZQtpx5lBFoERxI//z40EFmQLgn2R27PZkP0fDVGmaj5thdoE7JEREPBR6nFJ1+CCAFgq9mVF91dhUcBRgGHMWjju0TZN7xi+nVGmaH8wG2IeaUHH+ibyqRTuxZCVGyAhgrZQi0KrtlYgHYAV1JXvET8IjfF7jnb4mPpMtg2TZbr0rpSnwQO9SzjLAauxV83Zj2j1FU9QtX0fQMfD1VM6zWn9D00agz3fOr866OwRF1mUe9EmaDL8d9HflF5jS/M8Yd1rHzsWzt87HMdr0D+sB6HixPXV/EyqH6QvCqBOE9qPXMTsmMqI3TMG9eNc7P9EJYYvK/KdUsZz0raZF06Hu+DCxBD+BZv1L1RKeAcXsZpnNtgtiEmEOeyRHx0zBvchtnHBWAUFa66q86ggltuj5HZU73pVdAcMHhAEX5xHys/Ge/j43IO/IvfZdxmM4FVDdOs1X1Twr3YWciTYPjZopXZILOxgWElVGIjs5V/Yw752QrxU/A1fGdL4yciz551U45qILa+fbsOmeutekud2I7BeJJhmPr3L07KlN0Y/eMzpmb2JzYntgfXfIr9nLWXgl0RuPLO1xzxdqPOPNK8FxJn+rXhP4HON5K31PHDhmjlm6fAAAAAElFTkSuQmCC);
      background-size: 50% auto;
      background-repeat: no-repeat;
      background-position: 0% 100%;
      content: '';
      position: absolute;
      left: -2.5rem;
      bottom: -0.75rem;
      width: 100%;
      height: 40px;
      opacity: 0.8;
      z-index: -1;
      transition: 300ms opacity;
    }
  }

  &__image {
    margin-bottom: var(--space-m-l);
    padding-left: 20px;
  }

  &__role {
    opacity: 0.8;
    margin-top: 2px;
  }

  &__toggle {
    box-shadow: 0 0 0 1em color('neutral');

    &:active,
    &:focus {
      box-shadow: 0 0 0 1em color('neutral');
    }

    &:not(.c-testimonial__toggle--active) {
      display: none;
    }
  }

  &__content {
    position: relative;
    transition: 300ms height;
    overflow: hidden;

    &:after {
      content: '';
      background: linear-gradient(rgba(color('neutral'), 0), rgba(color('neutral'), 1));
      position: absolute;
      height: 4em;
      bottom: 0;
      width: 100%;
      left: 0;
      pointer-events: none;

      opacity: 0;
      visibility: hidden;
      transition: 300ms opacity, 300ms visibility;
    }

    &--short {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    &:hover {
      img {
        transform: rotate(0deg);
      }

      figure:after {
        opacity: 0;
      }
    }
  }
}
