.c-section-header {
  text-align: center;
  margin-bottom: var(--space-xl-2xl);
  
  & > * {
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }

  &__scroll {
    color: color('coral');
    font-size: var(--step--2);
    text-transform: uppercase;
    letter-spacing: 0.17em;
    font-family: $font-stack-heading;
    display: block;
    padding-top: var(--space-s);

    &:after {
      content: '';
      display: block;
      height: var(--space-l);
      margin: var(--space-s) auto 0;
      width: 1px;
      background: currentColor
    }

    span {
      transition: 300ms transform;
      display: block;
    }

    &:hover span {
      transform: translateY(5px);
    }
  }
}
