.c-popular-articles {
  --bg: #{color('neutral', 'step--4')};
  background: var(--bg);
  position: relative;
  padding: var(--gutter) 0;
  margin-left: calc(-1 * var(--bleed-space));
  margin-right: var(--negative-gutter);
  overflow: hidden;

  & > header {
    padding-left: var(--bleed-space);
  }

  .c-article-preview-tiny {
    flex: 1;
    display: flex;
    flex-direction: column;

    footer {
      margin-top: auto;
      padding-top: var(--space-2xs)
    }
  }

  .c-lessons__slides {
    margin-bottom: var(--space-xl);
    position: relative;
    z-index: 1;
    margin: var(--space-l-2xl) 0 0 0;
    max-width: 100%;
    width: calc(100vw - var(--gutter));

    .c-lessons__slider > ol {
      gap: 0;
    }

    .c-lessons__slider > ol > li {
      flex-shrink: 0;
      padding-bottom: var(--space-s);
      border-right: 2px solid $border--dark-blue;
      padding-right: var(--gutter);
      width: calc(263px + var(--gutter));
      min-height: 300px;
      display: flex;
      flex-direction: column;
      
      &:first-child {
        padding-left: var(--bleed-space);
        width: calc(263px + var(--bleed-space));
      }

      & + li {
        padding-left: var(--gutter);
        margin-left: 0;
      }
    }

    &:after {
      display: none;
    }

    .c-popular-articles__footer {
      border-top: 2px solid $border--dark-blue;
      width: calc(100% + var(--gutter));
      padding: 0 var(--gutter) 0 var(--bleed-space);
      align-items: center;
      padding-top: var(--space-s);
    }

    .fade-out {
      background: $blue-gradient;
      
      svg {
        color: color('primary');
      }
    }

    .c-lessons__controls {
      justify-content: flex-end;
      gap: var(--space-s);
      position: static;
      width: auto;
      margin: 0;
      padding: 0;

      & > * + * {
        margin-left: 0;
      }
    }
    
    .c-lessons__count {
      transform: none;
      position: static;
      padding: 0;
      margin: 0;

      &:after {
        display: none;
      }
    }

    @media (min-width: 50em) {
      max-width: calc(var(--bleed-space) + (((100vw - var(--bleed-space) * 2)) * 0.666) + var(--gutter) / 2);

      ol {
        padding-right: 0;
      }

      .c-popular-articles__footer {
        margin-left: var(--bleed-space);
        padding-left: 0;
        width: calc(100% - var(--bleed-space));
      }
    }
  }
}