.u-link {
  color: color('primary');
  text-decoration: none;

  &--testimonial {
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    text-decoration-color: color('primary');
  }
}

.u-white-link {
  color: color('white');
  font-size: 0.95em;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-color: color('primary');
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.u-highlight {
  background: $primary-gradient;
  color: color('white', 'step-1');
  text-shadow: 1px 1px 1px rgba(0 0 0 / 50%);
  border-radius: var(--border-radius);
  padding: var(--space-2xs);
  display: block;
  margin-top: var(--space-3xs-2xs);
}

.u-link-highlight {
  color: color('dynamic', 'on-primary');
  background: $primary-gradient;
  text-shadow: $shadow;
  display: inline-block;
}

.u-link-highlight--orange {
  background: $primary-gradient;
}

.u-link-highlight--blue {
  background: $blue-gradient-reverse;
}

.u-lightblue {
  color: color('bright-blue', 'step-1');
}
