.c-hero {
//   overflow: hidden;

  .u-wrap {
    position: relative;
  }

  &__image {
    max-width: 1400px;
    margin: 0 auto var(--space-m-l);
    position: relative;

    svg {
      overflow: visible;
      display: none;
      margin-left: auto;
      margin-right: auto;
    }

    svg.hero-anim--ux {
      display: block;
    //   max-width: 600px;
      max-height: 65vh;
	  /* To align with the sizes of SIDP */
      margin: 0 auto;
      visibility: hidden;
    }

    svg.hero-anim--mobile {
      display: block;
      max-width: 600px;
      margin: 0 auto;
    }

    svg.hero-anim--desktop {
      max-height: 65vh;
    }

    @media (min-width: 1000px) {
      svg.hero-anim--mobile {
        display: none;
      }
      svg.hero-anim--desktop {
        display: block;
      }
    }
  }

  &__cta {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    animation: fade-in 1.5s ease-out 1s forwards;
	  text-shadow: $shadow;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  .fork,
  .knife {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }

  .fork {
    right: 100%;
  }

  .knife {
    left: 100%;
  }

  .c-section-header {
    margin-bottom: 0;
  }
}
