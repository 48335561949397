.c-staggered-cards {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-l-xl);
  }

  @media (min-width: 600px) {
    &__content {
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--gutter);
    }
  }
}

.c-staggered-card {
  .c-icon {
    font-size: 4em;
  }

  & > * {
    max-width: 370px;
  }

  @media (max-width: 599px) {
    text-align: center;

    & > * {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 600px) {
    &:nth-child(2n+2) {
      padding-top: 104px;
    }
  }

  @media (min-width: 900px) {
    &:nth-child(4n+2) {
      padding-left: 50px;
    }

    &:nth-child(4n+3),
    &:nth-child(4n+4) {
      padding-left: 100px;
    }
  }

  @media (min-width: 1200px) {
    &:nth-child(4n+2) {
      padding-left: 100px;
    }

    &:nth-child(4n+3),
    &:nth-child(4n+4) {
      padding-left: 200px;
    }
  }
}
