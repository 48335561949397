.c-article-preview-huge {
  display: flex;
  flex-direction: column;
  gap: var(--space-s-m);
  border-bottom: 4px solid $border--dark-blue;
  padding-block: var(--gutter);

  &__header {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: var(--space-s-m);

    h2 {
      line-height: 1.13;
    }

    > :first-child {
      flex-basis: 350px;
      flex-grow: 1;
    }

    > :last-child {
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: 50%;
    }
  }

  img {
    aspect-ratio: 1.34;
    object-fit: cover;
    border-radius: var(--space-2xs);
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-4xs) var(--space-xs-s);
    align-items: center;

    .c-twi span {
      height: 1em;
      white-space: nowrap;
    }
  }
  
  &__footer {
    display: flex;
    gap: var(--space-s);
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (min-width: 50em) {
      padding-right: var(--space-s);
    }

    p {
      line-height: 1.4;
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: 88%;
    }
  }
}

.c-h + .c-article-preview-huge {
	padding-block: 0 var(--gutter);
}
