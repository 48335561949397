.c-input {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  font-weight: 700;
  padding: var(--space-xs) 0;
  color: inherit;
  font-size: var(--step-4);
  font-family: $font-stack-heading;
  display: block;
  width: 100%;
  caret-color: color('white', 'step-1');

  margin-top: var(--space-2xs);
  background-color: $input-background;
  border: 2px solid $blue-gradient--border;
  border-radius: 6px;
  padding: var(--space-xs);

  &::placeholder {
    color: color('neutral', 'step-5');
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 1px 4px color('primary');
    border: 2px solid transparent;

    &::placeholder {
      color: color('neutral', 'step-6');
    }
  }
}

.c-label {
  display: block;
  text-transform: uppercase;
  font-family: $font-stack-heading;
  letter-spacing: 0.1em;
  font-size: var(--step--1);
}

.c-input-inline-button {
  position: relative;

  [type='submit'] {
    margin-top: var(--space-m);
  }

  &--wide {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: var(--space-s);


    input.c-input {
      text-align: left;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: color('white') !important;
  caret-color: color('white', 'step-1');
}
