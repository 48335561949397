.c-circle {
  background: $primary-gradient;
  border-radius: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-stack-heading;
  font-size: 0.95em;
  z-index: 1;
  position: absolute;
  transition: 300ms transform, margin .2s ease-in-out, box-shadow .2s ease-in-out;
  box-shadow: 0 4px 0 color('primary', 'step--4');
  text-shadow: $shadow;
  color: color('dynamic', 'on-primary') !important;

  &--small {
    width: 190px;
    height: 190px;
    padding: var(--space-xs);
    transform: rotate(var(--circle-rotate, -15deg));
  }

  &--with-icon {
    --circle-rotate: 0deg;
  }

  .c-icon--taster {
    font-size: 1.5em;
    margin-bottom: var(--space-2xs);
  }

  &--large {
    width: 224px;
    height: 224px;
    padding: var(--space-s);
    transform: rotate(var(--circle-rotate, 15deg));
    margin-left: auto;
    margin-right: var(--gutter);
    right: 0;
    top: 0;

    @media (max-width: 990px) {
      display: none;
    }
  }

  &__flask {
    margin-bottom: var(--space-3xs);
  }

  @media (prefers-reduced-motion: no-preference) {
    &:hover {
      --circle-rotate: 0deg;
    }
  }

  &:hover {
      --slide-offset: 80px;
    }

	&:active,
	&:focus {
		margin-top: 5px;
		box-shadow: 0 4px transparent;
	}
}
