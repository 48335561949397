.has-js .without-js,
.no-js .with-js {
  display: none;
}

.u-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.u-display-none {
  display: none;
}

.u-display-block {
  display: block;
}

.u-display-inline-block {
  display: inline-block;
}

.u-fill {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;

  object-fit: cover;
}

.u-fill--link {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 200%;
}

.u-relative {
  position: relative;
}

.u-sticky {
  position: sticky;
  top: 0;
}

.u-split {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &--top {
    align-items: flex-start;
  }

  &--bottom {
    align-items: flex-end;
  }

  & > :first-child {
    padding-right: var(--space-s);
  }

  @supports (gap: 1rem) {
    gap: var(--space-s-m);

    & > :first-child {
      padding-right: 0;
    }
  }
}

.u-row {
  display: flex;
  gap: var(--space-s-m);
}

.u-remove-pointer-events {
  pointer-events: none;
}
