.c-sidebar-newsletter {
  background: $blue-gradient;
  padding: var(--space-s-m);
  display: flex;
  flex-direction: column;
  border-radius: var(--space-2xs) 0px 0px 0px;
  padding: var(--space-s-m);
  border-bottom: 2px solid color('mint', 'step--1');
  position: relative;

  > svg {
    margin: var(--space-s-m) 0 calc(-1 * var(--space-s-m) + -2px) auto;
    display: block;
    max-width: 100%;
    height: auto;
  }

  &:after {
    content: '';
    width: var(--space-xs);
    height: 2px;
    background: color('neutral', 'step--3');
    position: absolute;
    right: 250px;
    bottom: -2px;
    z-index: 1;
  }

  label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .c-input-inline-button {
    display: flex;
    flex-direction: column;
    padding-top: var(--space-m);
    gap: var(--space-s);
  }

  .c-input {
    font-size: 1.2rem;
	// background: $blue-gradient;
  }

  .c-button {
    // background: $primary-gradient;
    // border-color: transparent;
    // font-size: var(--step-2);
    // padding: var(--space-3xs) var(--space-xs);
    // width: auto;
    // border-radius: var(--space-3xs);
    margin: 0;
	display: flex;

    // &:hover,
    // &:active,
    // &:focus {
    //   border: 2px solid transparent;
    // }
  }
}
