@font-face {
	font-family: 'Mija';
	src: url('../../static/fonts/Mija_Bold-subset.woff2') format('woff2'),
		url('../../static/fonts/mija_w03_bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mija';
	src: url('../../static/fonts/Mija_Regular-subset.woff2') format('woff2'),
		url('../../static/fonts/mija_w03_regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

/* Elena Web Regular */
@font-face {
	font-family: 'Elena';
	src: url('../../static/fonts/ElenaWebRegular-subset.woff2') format('woff2'),
		url('../../static/fonts/ElenaWebRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

/* Elena Web Bold */
@font-face {
	font-family: 'Elena';
	src: url('../../static/fonts/ElenaWebBold-subset.woff2') format('woff2'),
		url('../../static/fonts/ElenaWebBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

/* Elena Web Regular Italic  */
@font-face {
	font-family: 'Elena';
	src: url('../../static/fonts/ElenaWebRegularItalic-subset.woff2') format('woff2'),
		url('../../static/fonts/ElenaWebRegularItalic.woff') format('woff');
	font-style: italic;
	font-display: swap;
}

$weights: (
  base: 400,
  semi: 600,
  bold: 700
);

$line-heights: (
  base: 1.4,
  prose: 1.5,
);

$letter-spacings: (
  '4%': 0.04em,
  '2%': 0.02em,
  base: -0.01em,
  '-2%': -0.02em,
  '-3%': -0.03em
);

$font-stack-heading: 'Mija', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$font-stack: 'Elena', Georgia, serif;

/* @link https://utopia.fyi/type/calculator?c=320,16,1.067,1272,16,1.125,10,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1272;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );
}

@media screen and (min-width: 1272px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f--2-min: 12.00;
  --f--2-max: 12.00;
  --step--2: calc(
    ((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) *
      var(--fluid-bp)
  );

  --f--1-min: 14.00;
  --f--1-max: 14.00;
  --step--1: calc(
    ((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) *
      var(--fluid-bp)
  );

  --f-0-min: 16.00;
  --f-0-max: 16.00;
  --step-0: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );

  --f-1-min: 18.00;
  --f-1-max: 18.00;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-2-min: 20.00;
  --f-2-max: 20.00;
  --step-2: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-3-min: 20.00;
  --f-3-max: 22.00;
  --step-3: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 22.00;
  --f-4-max: 24.00;
  --step-4: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 26.00;
  --f-5-max: 30.00;
  --step-5: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );

  --f-6-min: 26.00;
  --f-6-max: 36.00;
  --step-6: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );

  --f-7-min: 28.00;
  --f-7-max: 45.00;
  --step-7: calc(
    ((var(--f-7-min) / 16) * 1rem) + (var(--f-7-max) - var(--f-7-min)) *
      var(--fluid-bp)
  );
}