.c-h {
  font-weight: 700;
  font-family: $font-stack-heading;
  color: inherit;
  line-height: lh();

  &-7 {
    font-size: var(--step-7); // Heading 1
    line-height: calc(((32 / 16) * 1rem) + (45 - 32) * var(--fluid-bp));
    letter-spacing: calc(((0.31 / 16) * 1rem) + (0.5 - 0.31) * var(--fluid-bp));
  }

  &-6 {
    font-size: var(--step-6); // Heading 2
    line-height: calc(((30 / 16) * 1rem) + (40 - 30) * var(--fluid-bp));
    letter-spacing: calc(((0.29 / 16) * 1rem) + (0.4 - 0.29) * var(--fluid-bp));
  }

  &-5 {
    font-size: var(--step-5); // Heading 3
    line-height: calc(((30 / 16) * 1rem) + (34 - 30) * var(--fluid-bp));
    letter-spacing: calc(
      ((0.29 / 16) * 1rem) + (0.33 - 0.29) * var(--fluid-bp)
    );
  }

  &-4 {
    font-size: var(--step-4); // Heading 4
    line-height: calc(((26 / 16) * 1rem) + (28 - 26) * var(--fluid-bp));
    letter-spacing: calc(((0 / 16) * 1rem) + (0.27) * var(--fluid-bp));
  }

  &-3 {
    font-size: var(--step-3);
  }

  &-2 {
    font-size: var(--step-2);
  }

  &-1 {
    font-size: var(--step-1);
  }

  &-0 {
    font-size: var(--step-0);
  }

  &--1 {
    font-size: var(--step--1);
  }

  &--rainbow {
    --blue-gradient: linear-gradient(-45deg, #84b6ff 35%, #f0f6ff);
    --rainbow-gradient: linear-gradient(
      45deg,
      #fbbebc 0,
      #ff6a66 35%,
      #7fb3ff 60%,
      #35c3ff 100%
    );
    background-image: var(--rainbow-gradient, #{color('white', 'step-1')});
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    filter: drop-shadow(0 0 2rem #000);
    text-shadow: none;
    letter-spacing: 0;
  }
}
