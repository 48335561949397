.c-articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: var(--gutter);

  &:nth-last-child(2) {
    padding-bottom: var(--space-l);
  }
}

.c-article-grid-graphic {
  position: relative;
  width: calc(100% + var(--gutter) * 2 + var(--bleed-space));
  margin-left: calc(var(--negative-gutter) * 2);

  // didn't want to mess anything else up 🫠 Inheriting a height/width from o-grid--article-switch
  svg.anim-meals {
    display: block;
    width: 100%!important;
    height: unset!important;
    pointer-events: none;
    max-width: 800px;
  }

  &__spacer {
    aspect-ratio: 0.8;
    width: 100%;
  }

  + h1 {
    margin-top: calc(-1 * var(--space-s-l));
    
    &:after {
      border-bottom: 1px solid $border;
      padding-bottom: var(--gutter);
      content: '';
      display: block;
      margin-inline: var(--negative-gutter);
    }
  }

  @media (hover: hover) {
    .c-animation-toggle:hover span {
      opacity: 1
    }
  }

  .c-animation-toggle {
    --toggle-size: 2.2rem;
    position: absolute;
    left: calc(var(--gutter) * 2);
    top: var(--space-s);
    background: linear-gradient(92.22deg, rgba(71, 109, 177, 0.7) -14.67%, rgba(71, 109, 177, 0.7) 52.67%, rgba(254, 73, 70, 0.75) 102.8%);
    padding: 2px;
    width: calc(var(--toggle-size) * 2);
    height: calc(var(--toggle-size) + 6px);
    border-radius: var(--toggle-size);
    // text-indent: 120%;
    white-space: nowrap;
    display: block;

    span {
      position: absolute;
      left: 120%;
      top: 50%;
      bottom: 50%;
      transform: translateY(-50%, -50%);
      line-height: 0;
      margin-top: -1px; //optical nudge
    }

    @media (hover: hover) {
      span {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    &,
    &-input {
      scroll-margin-top: 0;
    }

    &:after {
      content: '';
      background: color('neutral');
      position: absolute;
      left: 3px;
      top: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: var(--toggle-size);
      z-index: 5;
    }

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70% auto;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='20' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath stroke='%23FFF8EC' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1.6' d='M12.853 4.485V6.3M12.853 10.112v1.814M16.556 8.203h-1.804M10.959 8.203H9.155M5.352 13.03v1.286M5.352 17.024v1.29M7.98 15.673H6.697M4.003 15.673H2.724'/%3E%3Ccircle cx='4.04' cy='8.2' r='.8' fill='%23FFE895'/%3E%3Ccircle cx='11.24' cy='18.6' r='.8' fill='%23FFE895'/%3E%3Ccircle cx='8.04' cy='2.6' r='1.6' fill='%23FFE895'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M.84.2h16.8v19.2H.84z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      border-radius: 100%;
      height: var(--toggle-size);
      width: var(--toggle-size);
      position: absolute;
      left: 3px;
      top: 3px;
      z-index: 10;
      content: '';
    
      transition: 300ms transform, 300ms filter;
      background-color: color('neutral', 'step-3');
      transform: none;
    }
  }

  .c-animation-toggle-input:focus + * {
    background: color('primary');
  }
  
  .c-animation-toggle-input:checked + *:before {
    transform: translateX(100%) translateX(-6px) rotate(180deg);
    background-color: color('primary');
  }
  
  @media (min-width: 50em) {
    + h1:after {
      display: none;
    }

    .c-animation-toggle {
      top: var(--space-xs);
    }
  }
}
