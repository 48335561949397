.o-slat {
  padding-bottom: var(--space-l-xl);
  padding-top: var(--space-l-xl);

  &--top {
    padding-top: var(--space-l-xl);
  }

  &--bottom {
    padding-bottom: var(--space-l-xl);
  }
}