@each $name in (-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
  .u-step-#{$name} {
    font-size: var(--step-#{$name});
  }
}

.u-strong {
  font-weight: weight('bold');
}

.u-normal {
  font-weight: weight();
}

.u-ls {
  letter-spacing: ls();
}

.u-sans {
  font-family: $font-stack-heading;
}

.u-center {
  text-align: center;
}
