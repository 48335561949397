.c-cta-primary {
  background: $blue-gradient;
  font-family: $font-stack-heading;
  border-radius: var(--space-2xs);
  box-shadow: 0 4px 0 color('primary', 'step--4');
//   border-bottom: 6px solid color('dynamic', 'cta-primary-border');
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-s) 0 ;
  text-align: center;
  align-content: end;
  padding-left: var(--space-xs-s);
  line-height: 1.2;
  color: color('dynamic', 'on-primary');
  position: relative;

  &__content {
    padding: var(--space-xs-s);
  }

  a:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  a:focus {
    color: color('dynamic', 'on-primary')!important;
	outline-color: color('dynamic', 'on-primary');
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: var(--space-xs);

    svg {
      max-width: 138px;
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 40em) {
    grid-template-columns: min(30%, 138px) 1fr;
    gap: var(--space-s);
    text-align: left;
  }
}
