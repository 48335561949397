.c-article-preview-long {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  margin: 0 var(--negative-gutter) var(--space-m-l);
  padding: 0 var(--gutter) var(--space-m-l);
  border-bottom: 1px solid $border--dark-blue;

  .c-h {
    line-height: 1.13;
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: var(--space-xs) var(--space-s);
    flex-wrap: wrap;

    .c-twi span {
      height: 1em;
      white-space: nowrap;
    }
  }

  @media (min-width: 60em) {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 var(--space-s);
    padding: 0 0 var(--space-s);

    > :first-child {
      width: min(170px, 25%);
      flex-shrink: 0;
    }

    > :nth-child(2) {
      flex-grow: 1;
      padding-top: var(--space-2xs);
    }

    > :last-child {
      width: min(150px, 25%);
      flex-shrink: 0;
    }

    .c-categories,
    .c-categories ul {
      display: flex;
      flex-direction: column;
      gap: var(--space-3xs);
    }
  }
}
