.c-radio,
.c-checkbox {
  @extend .u-hidden;

  & + label {
    padding-left: var(--space-l);
    position: relative;
    font-size: var(--step--0);

    &:before {
      transform: translateY(-50%);
      position: absolute;
      content: '';
      border: 1px solid color('white');
      border-radius: 2px;
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 50%;
    }
  }

  &:focus + label:before {
    outline: 2px solid;
  }
}

.c-checkbox {
  &:checked + label:before {
    background: color('primary');
  }

  &:checked + label:after {
    transform: translateX(6.5px) translateY(-1.5px) translateY(-50%) rotate(45deg);
    position: absolute;
    content: '';
    width: 7px;
    height: 11px;
    border-bottom: 3px solid color('white');
    border-right: 3px solid color('white');
    left: 0;
    top: 50%;
  }
}

.c-radio {
  & + label:before {
    border-radius: 100%;
  }

  &:checked + label:before {
    background: color('primary');
  }
}
