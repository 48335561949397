.c-articles-major-cta {
  --graphic-offset: clamp(var(--bleed-space), 20vw, 300px);

  form {
    label {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .c-input-inline-button {
      display: flex;
      gap: var(--space-s);
      flex-wrap: wrap;

      > * {
        flex-grow: 1;
      }
    }

    .c-input {
      background: $blue-gradient;
      margin-top: 0;
    }

    .c-button {
      background: $primary-gradient;
      border-color: transparent;
      font-size: var(--step-4);
      padding: var(--space-xs);
      max-width: 160px;
      width: auto;
      margin-top: 0;
      text-align: center;
      display: flex;
    }
  }

  .o-grid > :last-child {
    pointer-events: none;

    figure {
      background: linear-gradient(
        transparent 40%,
        color('primary') 40%,
        color('primary') 100%
      );
      margin: 0 var(--negative-gutter);
      width: calc(100% + var(--gutter) * 2);

      > * {
        position: relative;
        width: 100%;
        top: auto;

        > * {
          position: absolute;
          height: 100%;
          width: 100%;
          right: 0;
          top: 0;
          max-width: 1000px;
        }
      }
    }
  }

  @media (min-width: 50em) {
    .o-grid > :first-child {
      align-self: center;
      // padding-right: var(--graphic-offset);
    }

    .o-grid > :last-child {
      //   background-color: color('primary');
      background-color: $blue-bg;
      position: relative;

      figure {
        width: calc(100% + var(--bleed-space) + var(--graphic-offset));
        margin-left: calc(-1 * var(--graphic-offset));
        margin-right: 0;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: none;
      }

      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: var(--bleed-space);
        // background-color: color('primary');
        background-color: $blue-bg;

        left: 100%;
        top: 0;
      }
    }
  }
}
