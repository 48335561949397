.c-newsletter-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: var(--gutter);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  display: none;

  .newsletter-open &,
  &:target {
    display: flex;
  }

  &__full-close,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &:after {
    background: rgba(0, 0, 0, 0.8);
  }

  &__full-close {
    z-index: 1;
  }

  &__wrapper {
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
    background: color('neutral');
    padding: var(--gutter);
    position: relative;
    z-index: 2;
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
	border-radius: var(--border-radius);

    @media (max-width: 28em) {
      .c-section-header {
        text-align: left;
        padding-left: 0;
        padding-right: var(--space-l);
        --space-m: var(--space-s);

        .c-h-6 {
          font-size: var(--step-4);
        }

        .o-prose {
          font-size: var(--step-0);
        }
      }
    }
  }
  
  &__close {
    background: color('neutral', 'step--1');
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    right: var(--gutter);
    top: var(--gutter);
    border-radius: 100%;
    transition: 300ms transform, 300ms background;

    &:hover {
      background: color('neutral', 'step--2');
      transform: rotate(90deg);
    }

    &:before,
    &:after {
      content: '';
      width: 22px;
      height: 1px;
      background: color('primary');
      position: absolute;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      left: 50%;
      top: 50%;
    }

    &:after {
      height: 22px;
      width: 1px;
    }

    @media (max-width: 28em) {
      margin-right: -0.5rem;
    }
  }
}
