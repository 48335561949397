.c-creator {
  overflow: hidden;

  &__header {
    margin-bottom: calc(-0.9 * var(--space-l-xl));
  }

  &__image {
    margin-left: var(--negative-gutter);
    margin-right: var(--negative-gutter);
    position: relative;
    z-index: -1;
  }

  &__content {
    margin-right: var(--negative-gutter);
    background: color('neutral');
    padding: var(--gutter);
    margin-top: var(--negative-gutter);
  }

  @media (max-width: 639px) {
    &__image {
      height: 0;
      padding-bottom: 100%;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0%;
        top: 0;
        object-fit: cover;
        object-position: left center;
      }
    }
  }

  @media (min-width: 640px) {
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: var(--gutter);
      align-items: center;
    }

    &__header {
      grid-column: 1 / 6;
      grid-row: 1 / 1;
      margin-bottom: 0;
    }

    &__image {      
      grid-column: 5   / 13;
      grid-row: 1 / 1;
    }

    &__content  {
      grid-column: 5 / 13;
      margin-top: calc(3 * var(--negative-gutter));
    }
  }

  @media (min-width: 60em) {
    &__header {
      grid-column: 1 / 5;
    }

    &__image {
      grid-column: 3 / 11;
    }

    &__content  {
      grid-column: 7 / 13;
    }
  }
}